<template>
  <div class="card">
    <div
      v-b-toggle="`collapseContent`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Content</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      v-if="activity.content"
      :id="`collapseContent`"
      visible
    >
      <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
        <div class="card-body">
          <!-- Conference -->
          <div
            v-if="activity.type == CONFERENCE"
            class="row"
          >
            <div class="col-sm-6">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.type_activity'] }}</label>
                <v-select
                  v-model="activity.content.type"
                  label="name"
                  :options="types"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="col-sm-6"
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.authorization'] }}</label>
                <v-select
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="activity.content.status"
                  label="status"
                  :options="statuses"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <div class="col-sm-9">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.title_talk'] }} </label>
                <input
                  v-model="activity.content.title"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.date_activity'] }}</label>
                <date-picker
                  v-model="activity.content.date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
          </div>
          <!-- End conference -->
          <!-- PHDs -->
          <div
            v-if="activity.type == PHDS"
            class="row"
          >
            <div class="col-sm-4">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.type'] }}</label>
                <v-select
                  v-model="activity.content.phd_type"
                  label="name"
                  :options="phdTypes"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.rol'] }}</label>
                <v-select
                  v-model="activity.content.phd_role"
                  label="name"
                  :options="phdRoles"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="col-sm-4"
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.authorization'] }}</label>
                <v-select
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="activity.content.status"
                  label="status"
                  :options="statuses"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <div class="col-sm-12">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.title_thesis'] }} </label>
                <input
                  v-model="activity.content.title"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.presentation_date'] }}</label>
                <date-picker
                  v-model="activity.content.date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.recipient_name'] }}</label>
                <input
                  v-model="activity.content.recipient_name"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label d-block"
                >{{ labels['content.recipient_institution'] }}</label>
                <v-select
                  v-if="!activity.content.recipient_institution"
                  v-model="activity.content.host"
                  label="custom_name"
                  :options="hosts"
                  :get-option-key="option => option.id"
                  :placeholder="'Type to search...'"
                  @search="onSearch({ name: $event }, 'institutions/filter')"
                />
                <input
                  v-if="activity.content.recipient_institution"
                  v-model="activity.content.recipient_institution"
                  type="text"
                  class="form-control"
                >
                <a
                  class="float-end text-danger form-label"
                  @click="toggleRecipient"
                ><i data-feather="alert-triangle" /> <u>Click here</u> if the institution is not in this list</a>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.country'] }}</label>
                <v-select
                  v-model="activity.country"
                  label="name"
                  :options="countries"
                  :reduce="e => e.name"
                />
              </div>
            </div>
          </div>
          <!-- End PHDs -->
          <!-- Courses -->
          <div
            v-if="activity.type == COURSES"
            class="row"
          >
            <div class="col-sm-6">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.type_courses'] }}</label>
                <v-select
                  v-model="activity.content.course_type"
                  label="name"
                  :options="courseTypes"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="col-sm-6"
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.authorization'] }}</label>
                <v-select
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="activity.content.status"
                  label="status"
                  :options="statuses"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.title_courses'] }}</label>
                <input
                  v-model="activity.content.title"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.begin_date'] }}</label>
                <date-picker
                  v-model="activity.content.date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.end_date'] }}</label>
                <date-picker
                  v-model="activity.content.end_date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label d-block"
                >{{ labels['content.institution'] }}</label>
                <v-select
                  v-if="!activity.content.recipient_institution"
                  v-model="activity.content.host"
                  label="name"
                  :options="hosts"
                  :get-option-key="option => option.id"
                  :placeholder="'Type to search...'"
                  @search="onSearch({ name: $event }, 'institutions/filter')"
                />
                <input
                  v-if="activity.content.recipient_institution"
                  v-model="activity.content.recipient_institution"
                  type="text"
                  class="form-control"
                >
                <a
                  class="float-end text-danger form-label"
                  @click="toggleRecipient"
                ><i data-feather="alert-triangle" /> <u>Click here</u> if the institution is not in this list</a>
              </div>
            </div>
          </div>
          <!-- End Courses -->
          <!-- Managerial -->
          <div
            v-if="activity.type == MANAGERIAL"
            class="row"
          >
            <div class="col-sm-8">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.type'] }}</label>
                <v-select
                  v-if="!activity.content.other_managerial_type"
                  v-model="activity.content.managerial_type"
                  label="name"
                  :options="managerialTypes"
                  :get-option-key="option => option.id"
                  @input="changeStatus"
                />
                <div
                  v-if="activity.content.other_managerial_type"
                  class="input-group"
                >
                  <input
                    v-model="activity.content.other_managerial_type"
                    type="text"
                    class="form-control"
                  >
                  <a
                    class="btn btn-outline-primary"
                    @click="returnToSelect()"
                  >{{ labels['content.return_list'] }}</a>
                </div>
              </div>
            </div>
            <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="col-sm-4"
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.authorization'] }}</label>
                <v-select
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="activity.content.status"
                  label="status"
                  :disabled="!loggedUser.roles.includes('super-admin')"
                  :options="statuses"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <div class="col-sm-12">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.description'] }} </label>
                <textarea
                  v-model="activity.content.title"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.begin_date'] }}</label>
                <date-picker
                  v-model="activity.content.date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.end_date'] }}</label>
                <date-picker
                  v-model="activity.content.end_date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <!-- <div class="col-sm-6">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Other type of managerial activity</label>
                <input
                  v-model="activity.content.other_type"
                  type="text"
                  class="form-control"
                >
              </div>
            </div> -->
            <hr>
            <div class="col-sm-12">
              <div class="mb-1">
                <div class="form-check form-check-success">
                  <input
                    v-model="activity.content.virtual_activity"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="colorCheck3"
                  >{{ labels['content.virtual_activity'] }}</label>
                </div>
              </div>
            </div>
            <hr>
            <div class="col-sm-6">
              <h5>Industrial property <span
                :id="`field-ip`"
              ><i data-feather="info" /></span></h5>
              <b-tooltip
                triggers="hover"
                :target="`field-ip`"
              >
                <span v-html="`Please explain the reason why this activity might affect the ownership rights`" />
              </b-tooltip>
              <div class="mb-1">
                <div class="form-check form-check-inline">
                  <input
                    v-model="activity.content.industrial_property"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="false"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio1"
                  >No</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="activity.content.industrial_property"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="true"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio2"
                  >Yes</label>
                </div>
              </div>
              <div
                v-if="activity.content.industrial_property == 'true' || activity.content.industrial_property == true"
                class="mb-1"
              >
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.description'] }}</label>
                <textarea
                  v-model="activity.content.industrial_property_description"
                  class="form-control"
                />
                <!-- sólo aperece si se marca el checkbox -->
              </div>
            </div>
            <div class="col-sm-6">
              <h5>Employment contract <span
                :id="`field-ec`"
              ><i data-feather="info" /></span></h5>
              <b-tooltip
                triggers="hover"
                :target="`field-ec`"
              >
                <span v-html="`Do not forget to upload the contract in Support documentation`" />
              </b-tooltip>
              <div class="mb-1">
                <div class="form-check form-check-inline">
                  <input
                    v-model="activity.content.exployment_contract"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    value="false"
                    @click="changeStatus(null)"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadioA"
                  >No</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="activity.content.exployment_contract"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    value="true"
                    @click="changeStatus({name: 'Consultancy'})"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadioB"
                  >Yes</label>
                </div>
              </div>
              <div
                v-if="activity.content.exployment_contract == 'true' || activity.content.exployment_contract == true"
                class="mb-1"
              >
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.description'] }}</label>
                <textarea
                  v-model="activity.content.exployment_contract_description"
                  class="form-control"
                />
                <!-- sólo aperece si se marca el checkbox -->
              </div>
            </div>
          </div>
          <!-- End managerial -->
          <!-- Stay of research -->
          <div
            v-if="activity.type == STAYS_RESEARCH"
            class="row"
          >
            <div class="row">
              <label
                class="form-label form-group required"
              >{{ labels['content.type'] }} </label>
              <div
                v-for="stayType in stayResearchTypes"
                :key="stayType.id"
                class="col-md-4 mb-1"
              >
                <div class="form-check form-check-inline">
                  <input
                    v-model="activity.content.stay_research_type"
                    class="form-check-input"
                    type="radio"
                    name="publicationType"
                    :value="stayType"
                  >
                  <label
                    class="form-check-label"
                    for="publicationType1"
                  >{{ stayType.name }}</label>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.description'] }} </label>
                <textarea
                  v-model="activity.content.title"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.begin_date'] }}</label>
                <date-picker
                  v-model="activity.content.date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.end_date'] }}</label>
                <date-picker
                  v-model="activity.content.end_date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="col-sm-4"
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.authorization'] }}</label>
                <v-select
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="activity.content.status"
                  label="status"
                  :options="statuses"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <!-- <div class="col-sm-6">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Type of stay of research</label>
                <v-select
                  v-model="activity.content.stay_research_type"
                  label="name"
                  :options="stayResearchTypes"
                  :get-option-key="option => option.id"
                  @search="onSearch({ name: $event }, 'stayResearchTypes/filter')"
                />
              </div>
            </div> -->
            <!-- <div class="col-sm-6">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label d-block"
                >Institution <a
                  class="float-end text-danger"
                  @click="toggleRecipient"
                ><i data-feather="alert-triangle" /> <u>Click here</u> if the institution is not in this list</a></label>
                <v-select
                  v-if="!activity.content.recipient_institution"
                  v-model="activity.content.host"
                  label="name"
                  :options="hosts"
                  :get-option-key="option => option.id"
                  @search="onSearch({ name: $event }, 'institutions/filter')"
                />
                <input
                  v-if="activity.content.recipient_institution"
                  v-model="activity.content.recipient_institution"
                  type="text"
                  class="form-control"
                >
              </div>
            </div> -->
          </div>
          <!-- End Stay of research -->
          <!-- Disseminations -->
          <div
            v-if="activity.type == DISSEMINATIONS"
            class="row"
          >
            <div class="col-sm-6">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.type_dissemination'] }}</label>
                <v-select
                  v-model="activity.content.dissemination_type"
                  label="name"
                  :options="disseminationTypes"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.begin_date'] }}</label>
                <date-picker
                  v-model="activity.content.date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <div
              v-if="activity.content.different_end_date"
              class="col-sm-2"
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.different_end_date'] }}</label>
                <date-picker
                  v-model="activity.content.end_date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <div
              class="col-sm-6"
              :style="{ visibility: activity.content.dissemination_type.name === 'Other type' ? 'visible' : 'hidden' }"
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.other_type_disseminations'] }}</label>
                <input
                  v-model="activity.content.other_type"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-check form-check-success form-switch d-flex align-items-center mt-2 form-group required">
                <input
                  id=""
                  v-model="activity.content.different_end_date"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for=""
                ><small class="ms-1">{{ labels['content.different_end_date'] }} </small></label>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.title'] }} </label>
                <input
                  v-model="activity.content.title"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="col-sm-4"
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.authorization'] }}</label>
                <v-select
                  v-model="activity.content.status"
                  label="status"
                  :options="statuses"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
            <div class="col-sm-12">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content.description'] }}</label>
                <quill-editor v-model="activity.content.description" />
              </div>
            </div>
          </div>
          <!-- End Disseminations -->
          <div
            v-if="days > 30 && (this.activity.country && this.activity.country.toLowerCase() !== 'spain' && this.activity.country.toLowerCase() !== 'españa')
              || ((activity.content.managerial_type && activity.content.managerial_type.name.includes('Consultancy')) || activity.content.other_managerial_type)"
            class="div alert alert-warning"
          >
            <div class="alert-body">
              <p v-if="days > 30 && (this.activity.country && this.activity.country.toLowerCase() !== 'spain' && this.activity.country.toLowerCase() !== 'españa')">
                <i data-feather="alert-triangle" />
                Being an activity of more than 30 days you will need an authorization.
              </p>
              <p v-if="(activity.content.managerial_type && activity.content.managerial_type.name.includes('Consultancy')) || activity.content.other_managerial_type">
                <i data-feather="alert-triangle" />
                This activity requires ICREA's authorization
              </p>
            </div>
          </div>
          <div class="row">
            <hr>
            <div
              v-if="activity.type == CONFERENCE"
              class="col-sm-2"
            >
              <div class="mb-1">
                <div class="form-check form-check-success">
                  <input
                    v-model="activity.content['non-presenter-author']"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                  >
                  <label
                    class="form-check-label"
                    for="inlineCheckbox2"
                  >{{ labels['content.non_presenter_co_author'] }}</label>
                </div>
              </div>
            </div>
            <div
              class="col-sm-2"
            >
              <div class="mb-1">
                <div class="form-check form-check-success">
                  <input
                    v-model="activity.content.published"
                    type="checkbox"
                    class="form-check-input"
                    checked=""
                  >
                  <label
                    class="form-check-label"
                    for="colorCheck3"
                  >{{ labels['content.active'] }}</label>
                </div>
              </div>
            </div>
            <div
              v-if="activity.type != MANAGERIAL && activity.type != STAYS_RESEARCH"
              class="col-sm-2"
            >
              <div class="mb-1">
                <div class="form-check form-check-success">
                  <input
                    v-model="activity.content.virtual_activity"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="colorCheck3"
                  >{{ labels['content.virtual_activity'] }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse, BTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BCollapse,
    vSelect,
    DatePicker,
    BTooltip,
  },
  data() {
    return {
      CONFERENCE: 1,
      PHDS: 2,
      COURSES: 3,
      MANAGERIAL: 4,
      STAYS_RESEARCH: 5,
      DISSEMINATIONS: 6,
      alert: true,
    }
  },
  computed: {
    ...mapGetters({
      activity: 'activities/item',
      statuses: 'status/statuses',
      types: 'activityTypes/types',
      phdTypes: 'typesPhd/items',
      phdRoles: 'rolesPhd/items',
      stayResearchTypes: 'stayResearchTypes/items',
      courseTypes: 'courseTypes/items',
      hosts: 'institutions/institutions',
      disseminationTypes: 'disseminationTypes/items',
      managerialTypes: 'managerialTypes/items',
      loggedUser: 'auth/admin',
      countries: 'citizenships/items',
      labels: 'sectionLabels/itemLabels',
    }),
    host() {
      return this.activity.content.host
    },
    manualHost() {
      return this.activity.content.recipient_institution
    },
    endDate() {
      return this.activity.content.end_date
    },
    startDate() {
      return this.activity.content.date
    },
    days() {
      const date1 = new Date(this.activity.content.date)
      const date2 = new Date(this.activity.content.end_date)
      const difference = date2.getTime() - date1.getTime()
      this.replaceFeather()
      return Math.ceil(difference / (1000 * 3600 * 24))
    },
  },
  watch: {
    endDate() {
      this.checkDays()
    },
    startDate() {
      this.checkDays()
    },
    host() {
      if (this.activity.type !== this.PHDS) {
        this.updateAddress()
      }
    },
    manualHost() {
      if (this.activity.type !== this.PHDS) {
        this.updateAddress()
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('citizenships/fetch')
    await this.$store.dispatch('status/filterStatus', 'Activities')

    switch (this.activity.type) {
      case this.CONFERENCE:
        this.$store.dispatch('activityTypes/filter', { active: true })
        break
      case this.PHDS:
        this.$store.dispatch('institutions/filter', { active: true })
        this.$store.dispatch('typesPhd/filter', { active: true })
        this.$store.dispatch('rolesPhd/filter', { active: true })
        break
      case this.COURSES:
        this.$store.dispatch('institutions/filter', { active: true })
        this.$store.dispatch('courseTypes/filter', { active: true })
        break
      case this.MANAGERIAL:
        this.$store.dispatch('managerialTypes/filter', { active: true })
        break
      case this.STAYS_RESEARCH:
        this.$store.dispatch('institutions/filter', { active: true })
        this.$store.dispatch('stayResearchTypes/filter', { active: true })
        break
      case this.DISSEMINATIONS:
        this.$store.dispatch('disseminationTypes/filter', { active: true })
        break
      default:
        break
    }

    // 13/02/2025 -> Now all the status is changed on save
    // if status was authorized, dont change status
    // if (this.activity.content.status && (this.activity.content.status.id == 5 || this.activity.content.status.id == 20)) {
    // Perdon, este if me duele mas a mi que a ti
    // } else {
    //   this.checkDays()
    // }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    replaceFeather() {
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    checkDays() {
      if (this.days > 30 && (this.activity.country && this.activity.country.toLowerCase() !== 'spain' && this.activity.country.toLowerCase() !== 'españa')) {
        this.changeStatus({
          name: 'Consultancy',
        })
      } else if (this.activity.content.managerial_type && (this.activity.content.managerial_type.name.includes('Consultancy') || this.activity.content.other_managerial_type)) {
      } else {
        this.changeStatus(null)
      }
    },
    async changeStatus(item) {
      if (item && (item.name.includes('Consultancy') || item.name.includes('Other'))) {
        const status = this.statuses.find(e => (e.status.toLowerCase().includes('required authorization') || e.status.toLowerCase().includes('authorization required')))
        if (status) {
          this.activity.content.status = status
        }

        if (item.name.includes('Other')) {
          this.activity.content.other_managerial_type = 'Write the OTHER managerial type...'
          this.activity.content.managerial_type = null
        }
      } else {
        const resp = await this.$store.dispatch('activities/checkDates', { ...this.activity })
        if (resp.status !== 'warning' || resp.status === 'error') {
          const status = this.statuses.find(e => (e.status.toLowerCase().includes('authorization not required')))
          // if status was authorized, dont change status
          if (this.activity.content.status && this.activity.content.status.id == 5) {
            return
          }
          if (status) {
            this.activity.content.status = status
          } else {
            this.activity.content.status = null
          }
        }
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    returnToSelect() {
      this.activity.content.other_managerial_type = null
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    updateAddress() {
      if (this.activity.content.host && this.activity.content.host.id) {
        this.activity.event_name = this.activity.content.host.name
        this.activity.address = this.activity.content.host.address
        this.activity.lat = this.activity.content.host.latitude
        this.activity.lng = this.activity.content.host.longitude
        this.activity.country = this.activity.content.host.country
        this.activity.postal_code = this.activity.content.host.postal_code
      } else if (!this.activity.content.host && this.activity.content.recipient_institution) {
        this.activity.event_name = this.activity.content.recipient_institution
        this.activity.address = this.activity.content.recipient_institution
        this.activity.lat = null
        this.activity.lng = null
        this.activity.country = null
        this.activity.postal_code = null
      }
    },
    toggleRecipient() {
      if (this.activity.content.recipient_institution) {
        this.activity.content.recipient_institution = null
        this.activity.content.host = { name: 'Select one from the list' }
      } else {
        this.activity.content.host = null
        this.activity.content.recipient_institution = 'Write institution name here'
      }
    },
  },
}
</script>
